import { AppTokenData } from '@app/core/storage/app-token-data';
import { IUser } from './user.model';

export class LoginAction {
  static readonly type = `[User] SetIsLoggedInAction`;

  constructor(
    public token: AppTokenData,
    public returnUrl?: string,
  ) {}
}

export class SetCurrentUserAction {
  static readonly type = `[User] SetCurrentUserAction`;

  constructor(public payload: { user: IUser }) {}
}

export class UnsetCurrentUserAction {
  static readonly type = '[User] Unset Current User';

  constructor(public resetState = true) {}
}

export class LoadCurrentUserAction {
  static readonly type = `[User] LoadCurrentUserAction`;
}

export class LogoutAction {
  static readonly type = `[User] LogoutAction`;

  constructor(public redirectUrl = '') {}
}

export class LoginRedirectAction {
  static readonly type = '[User] Login Redirect';

  constructor(public payload?: { url: string }) {}
}

export class LogoutSuccessful {
  static readonly type = `[User] Logout Successful`;
}

export class ForbiddenRedirectAction {
  static readonly type = '[User] Forbidden Redirect';
}
