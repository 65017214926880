import { Selector } from '@ngxs/store';
import { UserState } from './user.state';
import { IUser, UserStateModel } from './user.model';

export class UserSelector {
  @Selector([UserState])
  static isLoggedIn(state: UserStateModel): boolean {
    return state.loggedIn;
  }

  @Selector([UserState])
  static currentUser(state: UserStateModel): IUser | null {
    return state.user;
  }

  @Selector([UserState])
  static permissions(state: UserStateModel): string[] {
    return state.user?.permissions || [];
  }

  @Selector([UserState])
  static currentUserId(state: UserStateModel): string {
    return state.user?.id || '';
  }
}
