import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';

import {
  ForbiddenRedirectAction,
  LoadCurrentUserAction,
  LoginAction,
  LoginRedirectAction,
  LogoutAction,
  LogoutSuccessful,
  SetCurrentUserAction,
  UnsetCurrentUserAction,
} from './user.action';
import { IJwtTokenUser, IUser, UserStateModel } from './user.model';
import { catchError, map, of, switchMap } from 'rxjs';
import { LocalStorage, STORE_TOKEN_KEY } from '@app/core/storage';
import { APP_PAGE } from '@app/core/configurations';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastViewComponent } from '@app/shared/components/toast/toast.view';

const userStateDefaults: UserStateModel = {
  loggedIn: false,
  user: null,
};

@State<UserStateModel>({
  name: 'user',
  defaults: userStateDefaults,
})
@Injectable()
export class UserState implements NgxsOnInit {
  private httpClient: HttpClient = inject(HttpClient);
  private _router = inject(Router);
  private _noteService = new ToastViewComponent();

  constructor(private permissionsService: NgxPermissionsService) {}

  @Action(LoginAction, { cancelUncompleted: true })
  login(
    { dispatch, patchState }: StateContext<UserStateModel>,
    action?: LoginAction,
  ) {
    // set local storage
    LocalStorage.set(STORE_TOKEN_KEY, action?.token);
    patchState({ loggedIn: true });

    dispatch(new LoadCurrentUserAction());
    // navigate
    void this._router.navigate([action?.returnUrl || '/']);
  }

  @Action(LoadCurrentUserAction)
  loadCurrentUser(
    ctx: StateContext<UserStateModel>,
    action: LoadCurrentUserAction,
  ) {
    const accessToken = LocalStorage.get(STORE_TOKEN_KEY)?.accessToken;

    if (accessToken) {
      const decodeToken: IJwtTokenUser = jwtDecode(accessToken);
      const _permissions: string[] = decodeToken?.permissions
      ? decodeToken?.permissions.split(',')
      : []
      this.permissionsService.loadPermissions(_permissions);
      const user: IUser = {
        id: decodeToken?.sub,
        firstName: decodeToken?.firstName,
        lastName: decodeToken?.lastName,
        avatar: decodeToken?.avatar || '',
        email: decodeToken?.email || '',
        position: decodeToken?.position || '',
        role: decodeToken?.role || '',
        roleName: decodeToken?.roleName || '',
        permissions: decodeToken?.permissions
          ? decodeToken?.permissions.split(',')
          : [],
      };
      console.log("Permissions: ", user.permissions);
      if (!user.role) {
        this._noteService.toastNoti(
          'error',
          'Your account has not been assigned to any roles. Please contact your system administrator for assistance.',
        );
      }
      ctx.dispatch(new SetCurrentUserAction({ user: user }));
    }
  }

  @Action(LogoutAction, { cancelUncompleted: true })
  logout({ dispatch }: StateContext<UserStateModel>, action?: LogoutAction) {
    const logoutRequest = this.httpClient.post<void>(`/api/logout`, null).pipe(
      // NOTE: If failed to log out, we need to clear user data
      map(() => {
        return { shouldResetState: false };
      }),
      catchError(() => of({ shouldResetState: true })),
    );

    return logoutRequest.pipe(
      //1. Clear current user data
      switchMap(({ shouldResetState }) => {
        return dispatch(new UnsetCurrentUserAction(shouldResetState));
      }),
      // 2. Redirect to login page with current url is returnUrl
      switchMap(() => {
        const payload = action?.redirectUrl
          ? { url: action?.redirectUrl }
          : undefined;
        return dispatch(new LoginRedirectAction(payload));
      }),
      // 3. Broadcast logout event to other tabs
      switchMap(() => {
        return dispatch(new LogoutSuccessful());
      }),
    );
  }

  @Action(LoginRedirectAction)
  loginRedirect(
    ctx: StateContext<UserStateModel>,
    { payload }: LoginRedirectAction,
  ) {
    const signInPath = `/${APP_PAGE.Auth}/${APP_PAGE.Login}`;

    let pathname: string | null = window.location.pathname;
    if (pathname === '/' || pathname === signInPath) {
      pathname = null;
    }

    const params = encodeURIComponent(payload?.url || '');
    const returnUrl =
      pathname && payload && payload.url ? `?returnUrl=${params}` : '';

    // Redirect to login page
    this._router.navigateByUrl(`${signInPath}${returnUrl}`);
  }

  @Action(SetCurrentUserAction)
  setCurrentUser(
    ctx: StateContext<UserStateModel>,
    action: SetCurrentUserAction,
  ) {
    const user = action.payload.user;
    if (!user) {
      return;
    }

    ctx.patchState({
      user: user,
    });
  }

  @Action(UnsetCurrentUserAction, { cancelUncompleted: true })
  unsetCurrentUser(
    { setState, patchState }: StateContext<UserStateModel>,
    { resetState }: UnsetCurrentUserAction,
  ) {
    patchState({ loggedIn: false });
    // Clear all session storage, and local storage data
    LocalStorage.remove(STORE_TOKEN_KEY);

    // Set state to default if needed
    if (resetState) {
      setState(userStateDefaults);
    }
  }

  @Action(ForbiddenRedirectAction)
  forbiddenRedirect({ dispatch }: StateContext<UserStateModel>) {
    this._router.navigateByUrl(`/forbidden`);
  }

  ngxsOnInit({ patchState }: StateContext<UserStateModel>): void {
    const tokenState = LocalStorage.get(STORE_TOKEN_KEY);
    if (typeof tokenState === 'object' && !!tokenState.accessToken) {
      patchState({ loggedIn: true });
    } else {
      patchState({ loggedIn: false });
    }
  }
}
