export class StorageSerializer {
  public static serialize(data: any): string {
    if (typeof data === 'object') {
      return btoa(encodeURIComponent(JSON.stringify(data)));
    } else {
      return btoa(encodeURIComponent(data));
    }
  }

  public static deserialize(data: any): any {
    try {
      return JSON.parse(decodeURIComponent(atob(data)));
    } catch {
      return {};
    }
  }
}

export class LocalStorage {
  static set(key: string, data: any): void {
    localStorage.setItem(key, StorageSerializer.serialize(data || ''));
  }

  static get(key: string): any {
    return StorageSerializer.deserialize(localStorage.getItem(key) || '');
  }

  static remove(key: string): void {
    localStorage.removeItem(key);
  }
}

export class SessionStorage {
  static set(key: string, data: any): void {
    sessionStorage.setItem(key, StorageSerializer.serialize(data || ''));
  }

  static get(key: string): any {
    return StorageSerializer.deserialize(sessionStorage.getItem(key) || '');
  }

  static remove(key: string): void {
    sessionStorage.removeItem(key);
  }
}
